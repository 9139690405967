export default {
  async update (Vue, id, formData, idsistema) {
    await Vue.$api.call(
      'clienteTelefono.update',
      {
        values: {
          idcliente_telefono: id,
          idttelefono: formData.idttelefono,
          telefono: formData.telefono,
          horario: formData.horario,
          observaciones: formData.observaciones,
          email: formData.email,
          nombre: formData.nombre,
          email_notifica_admon: formData.email_notifica_admon,
          email_notifica_comercial: formData.email_notifica_comercial,
          email_notifica_sat: formData.email_notifica_sat,
          para_todos_los_sistemas: formData.para_todos_los_sistemas,
        },
      }
    )
    if (idsistema) {
      const sistemaTelefono = await Vue.$online.sistemaTelefono.selectContactoDeSistema(id, idsistema)
      if (sistemaTelefono.length > 0) {
        await Vue.$api.call(
          'sistemaTelefono.update',
          {
            values: {
              idsistema_telefono: sistemaTelefono[0].idsistema_telefono,
              orden_cra: formData.orden_cra,
              codigo_cra: formData.codigo_cra,
              codigo_coaccion_cra: formData.codigo_coaccion_cra,
            },
          }
        )
      }
    }
  },
}
