<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <cliente-telefono-form
          :id="routeParams.idcliente_telefono"
          :idsistema="routeParams.idsistema"
          :has-perm-ver-personas-contacto-codigo-c-r-a="hasViewPerm(permissions.sistemas.verPersonasContactoCodigoCRA)"
          :has-perm-ver-personas-contacto-codigo-coaccion-c-r-a="hasViewPerm(permissions.sistemas.verPersonasContactoCodigoCoaccionCRA)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ClienteTelefonoForm from '../components/ClienteTelefonoForm'
import Data from './ClienteTelefonoEditData'

export default {
  components: {
    ClienteTelefonoForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar persona de contacto'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(
        this, this.routeParams.idcliente_telefono, this.formData, this.routeParams.idsistema
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.clienteTelefono, this.routeParams.idcliente_telefono)
      this.$appRouter.go(-1)
    },
  },
}
</script>
